import { ItemDiscount } from "./parsers/itemDiscountParser";

const itemDiscount: ItemDiscount = {
  "?xml": { "@_version": "1.0" },
  QBXML: {
    QBXMLMsgsRs: {
      ItemDiscountQueryRs: {
        ItemDiscountRet: [
          {
            ListID: "80000BC9-1550774520",
            TimeCreated: "2019-02-21T10:42:00-08:00",
            TimeModified: "2019-02-21T10:42:00-08:00",
            EditSequence: 1550774520,
            Name: "DISCOUNT",
            FullName: "DISCOUNT",
            IsActive: true,
            Sublevel: 0,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            DiscountRate: 0,
            AccountRef: {
              ListID: "8000002E-1510599165",
              FullName: "SC&R - Sales:07 Sales Discounts",
            },
          },
          {
            ListID: "80001495-1728490638",
            TimeCreated: "2024-10-09T09:17:18-08:00",
            TimeModified: "2024-10-09T09:17:18-08:00",
            EditSequence: 1728490638,
            Name: "30 Day Discount",
            FullName: "DISCOUNT:30 Day Discount",
            IsActive: true,
            ParentRef: { ListID: "80000BC9-1550774520", FullName: "DISCOUNT" },
            Sublevel: 1,
            ItemDesc: "End Of Summer Discount",
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            DiscountRatePercent: 20,
            AccountRef: {
              ListID: "8000002E-1510599165",
              FullName: "SC&R - Sales:07 Sales Discounts",
            },
          },
          {
            ListID: "800010B2-1635980091",
            TimeCreated: "2021-11-03T15:54:51-08:00",
            TimeModified: "2021-11-03T15:55:10-08:00",
            EditSequence: 1635980110,
            Name: "Amazon Discount",
            FullName: "DISCOUNT:Amazon Discount",
            IsActive: true,
            ParentRef: { ListID: "80000BC9-1550774520", FullName: "DISCOUNT" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            DiscountRate: 0,
            AccountRef: {
              ListID: "8000002E-1510599165",
              FullName: "SC&R - Sales:07 Sales Discounts",
            },
          },
          {
            ListID: "8000136B-1659984798",
            TimeCreated: "2022-08-08T11:53:18-08:00",
            TimeModified: "2022-08-08T11:53:18-08:00",
            EditSequence: 1659984798,
            Name: "Clipper Ad Discount",
            FullName: "DISCOUNT:Clipper Ad Discount",
            IsActive: true,
            ParentRef: { ListID: "80000BC9-1550774520", FullName: "DISCOUNT" },
            Sublevel: 1,
            ItemDesc: "Clipper Ad Discouint",
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            DiscountRatePercent: 10,
            AccountRef: {
              ListID: "8000002E-1510599165",
              FullName: "SC&R - Sales:07 Sales Discounts",
            },
          },
          {
            ListID: "800003D9-1510599165",
            TimeCreated: "2017-11-13T10:52:45-08:00",
            TimeModified: "2024-10-09T09:09:06-08:00",
            EditSequence: 1728490146,
            Name: "Contractor Discount",
            FullName: "DISCOUNT:Contractor Discount",
            IsActive: true,
            ParentRef: { ListID: "80000BC9-1550774520", FullName: "DISCOUNT" },
            Sublevel: 1,
            ItemDesc: "Contractor Discount Approved By Sales Manager",
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            DiscountRatePercent: 10,
            AccountRef: {
              ListID: "8000002E-1510599165",
              FullName: "SC&R - Sales:07 Sales Discounts",
            },
          },
          {
            ListID: "800013A6-1706658548",
            TimeCreated: "2024-01-30T15:49:08-08:00",
            TimeModified: "2024-01-30T15:49:08-08:00",
            EditSequence: 1706658548,
            Name: "Decks Direct Discount",
            FullName: "DISCOUNT:Decks Direct Discount",
            IsActive: true,
            ParentRef: { ListID: "80000BC9-1550774520", FullName: "DISCOUNT" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            DiscountRate: 0,
            AccountRef: {
              ListID: "8000002E-1510599165",
              FullName: "SC&R - Sales:07 Sales Discounts",
            },
          },
          {
            ListID: "800003DA-1510599165",
            TimeCreated: "2017-11-13T10:52:45-08:00",
            TimeModified: "2024-10-09T09:08:36-08:00",
            EditSequence: 1728490116,
            Name: "Discretionary Discount",
            FullName: "DISCOUNT:Discretionary Discount",
            IsActive: true,
            ParentRef: { ListID: "80000BC9-1550774520", FullName: "DISCOUNT" },
            Sublevel: 1,
            ItemDesc: "General Discount Approved By Sales Manager",
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            DiscountRate: 0,
            AccountRef: {
              ListID: "8000002E-1510599165",
              FullName: "SC&R - Sales:07 Sales Discounts",
            },
          },
          {
            ListID: "80001394-1694701493",
            TimeCreated: "2023-09-14T07:24:53-08:00",
            TimeModified: "2023-09-15T10:54:18-08:00",
            EditSequence: 1694800458,
            Name: "Email Campaign Discount",
            FullName: "DISCOUNT:Email Campaign Discount",
            IsActive: true,
            ParentRef: { ListID: "80000BC9-1550774520", FullName: "DISCOUNT" },
            Sublevel: 1,
            ItemDesc:
              "Discount used for customers who recieved an email offering a 10% discount on new orders.",
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            DiscountRatePercent: 10,
            AccountRef: {
              ListID: "8000002E-1510599165",
              FullName: "SC&R - Sales:07 Sales Discounts",
            },
          },
          {
            ListID: "800003DC-1510599165",
            TimeCreated: "2017-11-13T10:52:45-08:00",
            TimeModified: "2019-02-21T10:42:28-08:00",
            EditSequence: 1550774548,
            Name: "Friends-Family-Discount",
            FullName: "DISCOUNT:Friends-Family-Discount",
            IsActive: true,
            ParentRef: { ListID: "80000BC9-1550774520", FullName: "DISCOUNT" },
            Sublevel: 1,
            ItemDesc: "Friends & Family Discount- Must be approved by Eric",
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            DiscountRatePercent: 20,
            AccountRef: {
              ListID: "8000002E-1510599165",
              FullName: "SC&R - Sales:07 Sales Discounts",
            },
          },
          {
            ListID: "800003DD-1510599165",
            TimeCreated: "2017-11-13T10:52:45-08:00",
            TimeModified: "2019-02-21T10:42:36-08:00",
            EditSequence: 1550774556,
            Name: "Online Discount",
            FullName: "DISCOUNT:Online Discount",
            IsActive: true,
            ParentRef: { ListID: "80000BC9-1550774520", FullName: "DISCOUNT" },
            Sublevel: 1,
            ItemDesc:
              "Amazon=15% of merchandise & shipping and another 2.8% of taxes if included.\n\nPayPal=2.9% of merchandise & shipping + $0.30",
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            DiscountRate: 0,
            AccountRef: {
              ListID: "8000002E-1510599165",
              FullName: "SC&R - Sales:07 Sales Discounts",
            },
          },
          {
            ListID: "80000B6D-1533746974",
            TimeCreated: "2018-08-08T09:49:34-08:00",
            TimeModified: "2023-08-22T21:16:53-08:00",
            EditSequence: 1692764213,
            Name: "Warranty Replacement",
            FullName: "DISCOUNT:Warranty Replacement",
            IsActive: true,
            ParentRef: { ListID: "80000BC9-1550774520", FullName: "DISCOUNT" },
            Sublevel: 1,
            ItemDesc: "Items covered under SC&R 10 Year warranty",
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            DiscountRate: 0,
            AccountRef: {
              ListID: "8000017B-1692764132",
              FullName: "SC&R - Sales:08 Warranty Replacement",
            },
          },
          {
            ListID: "80001063-1631921908",
            TimeCreated: "2021-09-17T16:38:28-08:00",
            TimeModified: "2021-09-17T16:38:28-08:00",
            EditSequence: 1631921908,
            Name: "Weld SSR Hi PC Discount - 21.95",
            FullName: "DISCOUNT:Weld SSR Hi PC Discount - 21.95",
            IsActive: true,
            ParentRef: { ListID: "80000BC9-1550774520", FullName: "DISCOUNT" },
            Sublevel: 1,
            ItemDesc: "Discount for SSR Hi Post Caps that cost 21.95",
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            DiscountRate: 21.95,
            AccountRef: {
              ListID: "8000002E-1510599165",
              FullName: "SC&R - Sales:07 Sales Discounts",
            },
          },
          {
            ListID: "80001061-1631921837",
            TimeCreated: "2021-09-17T16:37:17-08:00",
            TimeModified: "2021-09-17T16:37:17-08:00",
            EditSequence: 1631921837,
            Name: "Weld SSR Hi PC Discount - 24.15",
            FullName: "DISCOUNT:Weld SSR Hi PC Discount - 24.15",
            IsActive: true,
            ParentRef: { ListID: "80000BC9-1550774520", FullName: "DISCOUNT" },
            Sublevel: 1,
            ItemDesc: "Discount for SSR Hi Post Caps that cost 24.15",
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            DiscountRate: 24.15,
            AccountRef: {
              ListID: "8000002E-1510599165",
              FullName: "SC&R - Sales:07 Sales Discounts",
            },
          },
          {
            ListID: "80001062-1631921890",
            TimeCreated: "2021-09-17T16:38:10-08:00",
            TimeModified: "2021-09-17T16:38:10-08:00",
            EditSequence: 1631921890,
            Name: "Weld SSR Hi PC Discount - 26.35",
            FullName: "DISCOUNT:Weld SSR Hi PC Discount - 26.35",
            IsActive: true,
            ParentRef: { ListID: "80000BC9-1550774520", FullName: "DISCOUNT" },
            Sublevel: 1,
            ItemDesc: "Discount for SSR Hi Post Caps that cost 26.35",
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            DiscountRate: 26.35,
            AccountRef: {
              ListID: "8000002E-1510599165",
              FullName: "SC&R - Sales:07 Sales Discounts",
            },
          },
          {
            ListID: "80001060-1631921807",
            TimeCreated: "2021-09-17T16:36:47-08:00",
            TimeModified: "2021-09-17T16:36:47-08:00",
            EditSequence: 1631921807,
            Name: "Weld SSR Hi PC Discount - 27.45",
            FullName: "DISCOUNT:Weld SSR Hi PC Discount - 27.45",
            IsActive: true,
            ParentRef: { ListID: "80000BC9-1550774520", FullName: "DISCOUNT" },
            Sublevel: 1,
            ItemDesc: "Discount for SSR Hi Post Caps that cost 27.45",
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            DiscountRate: 27.45,
            AccountRef: {
              ListID: "8000002E-1510599165",
              FullName: "SC&R - Sales:07 Sales Discounts",
            },
          },
          {
            ListID: "8000105E-1631921572",
            TimeCreated: "2021-09-17T16:32:52-08:00",
            TimeModified: "2021-09-17T16:34:14-08:00",
            EditSequence: 1631921654,
            Name: "Welded SSQ Hi PC Discount",
            FullName: "DISCOUNT:Welded SSQ Hi PC Discount",
            IsActive: true,
            ParentRef: { ListID: "80000BC9-1550774520", FullName: "DISCOUNT" },
            Sublevel: 1,
            ItemDesc:
              "Discount for SSQ Hi Post Caps included with welded posts",
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            DiscountRate: 32.95,
            AccountRef: {
              ListID: "8000002E-1510599165",
              FullName: "SC&R - Sales:07 Sales Discounts",
            },
          },
          {
            ListID: "8000105F-1631921698",
            TimeCreated: "2021-09-17T16:34:58-08:00",
            TimeModified: "2021-09-17T17:13:53-08:00",
            EditSequence: 1631924033,
            Name: "Welded SSQ Low Cor PC Discount",
            FullName: "DISCOUNT:Welded SSQ Low Cor PC Discount",
            IsActive: true,
            ParentRef: { ListID: "80000BC9-1550774520", FullName: "DISCOUNT" },
            Sublevel: 1,
            ItemDesc:
              "Discount for SSQ Low Corner Post Caps included with welded posts",
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            DiscountRate: 21.75,
            AccountRef: {
              ListID: "8000002E-1510599165",
              FullName: "SC&R - Sales:07 Sales Discounts",
            },
          },
          {
            ListID: "80000F08-1631043930",
            TimeCreated: "2021-09-07T12:45:30-08:00",
            TimeModified: "2021-09-07T13:04:25-08:00",
            EditSequence: 1631045065,
            Name: "Wholesale Cable Discount",
            FullName: "DISCOUNT:Wholesale Cable Discount",
            IsActive: true,
            ParentRef: { ListID: "80000BC9-1550774520", FullName: "DISCOUNT" },
            Sublevel: 1,
            ItemDesc: "Discount for Wholesale Cable Bundles on website",
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            DiscountRatePercent: 10,
            AccountRef: {
              ListID: "8000002E-1510599165",
              FullName: "SC&R - Sales:07 Sales Discounts",
            },
          },
          {
            ListID: "800003DE-1510599165",
            TimeCreated: "2017-11-13T10:52:45-08:00",
            TimeModified: "2022-02-18T19:27:25-08:00",
            EditSequence: 1645241245,
            Name: "Restock Fee",
            FullName: "Restock Fee",
            IsActive: true,
            Sublevel: 0,
            ItemDesc: "Merchandise return - 20% restock fee",
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            DiscountRatePercent: 20,
            AccountRef: {
              ListID: "8000016B-1645238628",
              FullName: "SC&R - Sales:02 Service Income",
            },
          },
        ],
        "@_statusCode": "0",
        "@_statusSeverity": "Info",
        "@_statusMessage": "Status OK",
      },
    },
  },
};

export default itemDiscount;
